import React from 'react';
import getText, { HtmlParser } from 'AppUtils/language';

import GamesSlider from '../subcomponents/GamesSlider';

import './Games.scss';
import Countdown from 'react-countdown';
import BannersSlider from '../subcomponents/BannersSlider';

const GamesComponent = (props) => {
	return (
		<div className="play-component vertical-center">
			<div className="title">
				<h2>
					{props.virtualGames ?
						getText('virtual_games[text_title]')
						:
						getText('games[text_title]')
					}
				</h2>
				<div>
					{props.virtualGames ?
						<HtmlParser html="virtual_games[text_subtitle]" />
						:
						<HtmlParser html="games[text_subtitle]" />
					}
				</div>
			</div>
			<GamesSlider
				lang={props.lang}
				games={props.games}
				virtualGames={props.virtualGames}
				userInfoGames={props.userInfoGames}
				handlePlayGame={props.handlePlayGame}
			/>
			<div className="tip">
				{props.virtualGames ?
					getText('virtual_games[text_tip]')
					:
					getText('games[text_tip]')
				}
			</div>
			<BannersSlider
				onBannerClick={props.onBannerClick}
				lang={props.lang}
				banners={props.banners}
				/>
		</div>
	);
};

export default GamesComponent;
