import { createSelector } from 'reselect';

const playMsg = state => state.play.msg;
const playStatusCode = state => state.play.statusCode;
const playGetGames = state => state.play.games;
const playGetBanners = state => state.play.banners;
const playGetExternalGames = state => state.play.externalGames;
const playGetGame = (state, id) => createSelector(
	[playGetGames], (games) => {
		return games && id && games.find(game => game.id == id);
	},
);
const playGetExternalGame = (state, id) => createSelector(
	[playGetExternalGames], (games) => {
		return games && id && games.find(game => game.id == id);
	},
);
const playStartGame = state => state.play.start;
const playFinishGame = state => state.play.finish;

const socketNextGameIn = state => state.play.nextGameIn;

export {
	playMsg,
	playStatusCode,
	playGetGames,
	playGetBanners,
	playGetExternalGames,
	playGetGame,
	playGetExternalGame,
	playStartGame,
	playFinishGame,
	socketNextGameIn,
};
