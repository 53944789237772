/**
 * Used by webpack as a provider and replacement for console.log .
 * For the moment it maps to console. but we can disable/enable it as we wish
 */

const noop = function () {};
const methods = ['debug', 'dir', 'info', 'log'];

methods.map((method) => {
    // DEBUG cookie might be on
    if (document.cookie.indexOf('__DEBUG__=') != -1) return;
    // App debug might be on
    // if(process.env.REACT_APP_WEBAPP_DEBUG) return;
    // If the method does not exists as console.METHOD
    if (!console[method] || !(process.env.REACT_APP_WEBAPP_DEBUG === 'true')) console[method] = noop;
});


export default console;


if ((typeof module !== 'undefined' && module !== null) && module.exports) {
    exports = module.exports = console;
} else {
    window.console = console;
}
