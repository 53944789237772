import React from 'react';

const RibbonComponent = (props) => {
	if(props.sizeBig) {
		return (
			<svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 320" style={{maxWidth: props.width + 'px'}} preserveAspectRatio="none">
				<path d="M10,4l130,31.5L270,4v323H10V4z" style={{fill: `${props.fill}`}}></path>
			</svg>
		)
	} else {
		return (
			<svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 86 86`} style={{maxWidth: props.width + 'px'}}>
				<path d="M10,4l33,8l33-8v82H10V4z" style={{fill: `${props.fill}`}}></path>
			</svg>
		)
	}
};

export default RibbonComponent;
