const LOAD_MSG = 'LOAD_MSG';
const SET_MSG = 'SET_MSG';

const USER_LOAD_INFO = 'USER_LOAD_INFO';
const USER_SET_INFO = 'USER_SET_INFO';

const USER_LOAD_UPDATE_INFO = 'USER_LOAD_UPDATE_INFO';
const USER_SET_UPDATE_INFO = 'USER_SET_UPDATE_INFO';

const USER_LOAD_POINTS = 'USER_LOAD_POINTS';
const USER_SET_POINTS = 'USER_SET_POINTS';

const USER_LOAD_CREDITS = 'USER_LOAD_CREDITS';
const USER_SET_CREDITS = 'USER_SET_CREDITS';

const USER_LOAD_LEADER_BOARD = 'USER_LOAD_LEADER_BOARD';
const USER_SET_LEADER_BOARD = 'USER_SET_LEADER_BOARD';

const USER_LOAD_EVENT = 'USER_LOAD_EVENT';
const USER_SET_EVENT = 'USER_SET_EVENT';

const USER_LOAD_LOGIN_WITH_TOKEN = 'USER_LOAD_LOGIN_WITH_TOKEN';
const USER_SET_LOGIN_WITH_TOKEN = 'USER_SET_LOGIN_WITH_TOKEN';

const USER_LOAD_UNSUBSCRIBE = 'USER_LOAD_UNSUBSCRIBE';
const USER_SET_UNSUBSCRIBE = 'USER_SET_UNSUBSCRIBE';

export {
	LOAD_MSG,
	SET_MSG,
	USER_LOAD_INFO,
	USER_SET_INFO,
	USER_LOAD_UPDATE_INFO,
	USER_SET_UPDATE_INFO,
	USER_LOAD_POINTS,
	USER_SET_POINTS,
	USER_LOAD_CREDITS,
	USER_SET_CREDITS,
	USER_LOAD_EVENT,
	USER_SET_EVENT,
	USER_LOAD_LOGIN_WITH_TOKEN,
	USER_SET_LOGIN_WITH_TOKEN,
	USER_LOAD_LEADER_BOARD,
	USER_SET_LEADER_BOARD,
	USER_LOAD_UNSUBSCRIBE,
	USER_SET_UNSUBSCRIBE,
};
