import React from 'react';
import clsx from 'clsx';

import UnsubscribePrompt from '../subcomponents/Prompt/main/UnsubscribePromptContainer';
import UnsubscribeText from '../subcomponents/Text/UnsubscribeTextComponent';

const UnsubscribeComponent = (props) => {
	return (
		<div className={clsx("unsubscribe-component","auth-component", "vertical-center", {"prefix-active": props.msisdn.prefix})}>
			<div className="prize"></div>
			{
				props.unsubscribeMethod === 'direct' ?
					<UnsubscribePrompt {...props} />
					:
					props.unsubscribeMethod === 'static-text' ?
						<UnsubscribeText {...props} />
						: null
			}
		</div>
	);
};

export default UnsubscribeComponent;
