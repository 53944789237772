import React from 'react';

import Prompt from '../subcomponents/prompt/PromptContainer';
import Text from '../subcomponents/text/TextComponent';

const SignupComponent = (props) => {
	return (
		<div className="signup-component">
			{props.type === 'prompt' ?
				<Prompt prefix={props.prefix} msisdn={props.msisdn} rtl={props.rtl} />
				:
				<Text />
			}
		</div>
	);
};

export default SignupComponent;
