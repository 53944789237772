import React from "react";
import getText from "AppUtils/language";

import Avatar from "../../common/avatar/AvatarComponent";

import "./Leaderboard.scss";

const LeaderboardComponent = (props) => {
	const renderFirstThree = () => {
		let topThree = props.users.filter((item, index) => index < 3);

		return topThree.map((item) => (
			<div className="top-three-single" key={item.nickname}>
				<div className="nickname">
					<Avatar size="50px">
						<img src={item.avatar}></img>
					</Avatar>
					<span className="name">{item.nickname}</span>
				</div>
				<div className="score">{item.points}</div>
			</div>
		));
	};

	const renderUsers = () => {
		let users = props.users.filter((item, index) => index > 2);

		console.log(users);
		return users.map((item, index) => (
			<tr className="users-single" key={item.nickname}>
				<td className="rank">{index + 4}</td>
				<td className="nickname">
					<Avatar size="30px">
						<img src={item.avatar}></img>
					</Avatar>
					<span className="name">{item.nickname}</span>
				</td>
				<td className="score">{item.points}</td>
			</tr>
		));
	};

	return (
		<div className="leaderboard-component">
			<h2>{getText("top[text_leaderboard]")}</h2>
			<div className="top-three">{renderFirstThree()}</div>
			<table className="users-listing">
				<thead>
					<tr className="users-single">
						<td className="rank">{getText("top[text_rank]")}</td>
						<td className="nickname">{getText("top[text_nickname]")}</td>
						<td className="score">{getText("top[text_points]")}</td>
					</tr>
				</thead>
				<tbody>{renderUsers()}</tbody>
			</table>
		</div>
	);
};

export default LeaderboardComponent;
