import React, {Component} from 'react';
import {connect} from 'react-redux';

import Signup from './SignupComponent';

import {
	appGetConfig
} from 'AppStore/selectors';

const stateToProps = state => ({
	appGetConfig: appGetConfig(state),
});

const actionsToProps = dispatch => ({
});

@connect(stateToProps, actionsToProps)
class SignupContainer extends Component {

	state = {
		type: '',
	}

	componentDidMount() {
		if(this.props.appGetConfig && this.props.appGetConfig.config && this.props.appGetConfig.config.users) {
			this.setState({
				type: this.props.appGetConfig.config.users.subscribeMethod
			})
		}
	}

	render() {
		return (
			<Signup
				{...this.state}
				rtl={this.props.rtl}
				prefix={this.props.prefix}
				msisdn={this.props.appGetConfig.config.users.msisdn}
			/>
		);
	}
}

export default SignupContainer;
