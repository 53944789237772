/**
 * @description Event triggered when a socket is connected to the server
 */
const SOCKET_CONNECT = 'connection';
/**
 * @description This event is triggered when the socket disconnects from the server
 */
const SOCKET_DISCONNECT = 'disconnect';
/**
 * @description This event is triggered when the connection times out with the socket
 */
const SOCKET_TIMEOUT = 'connect_timeout';

/**
 * @desc Event is used to broadcast the current number of live players
 */
const SOCKET_NUMBER_OF_PLAYERS = 'number_of_players';

/**
 * @desc event is used to ping back the user with the game state on connection
 */
const SOCKET_GAME_STATE = 'socket_game_state';

// User related events

/**
 * @description This event is received when the user connects to the web socket. In this instance, the user should pass additional identification information to the server
 */
const LIVEGAME_USER_CONNECTED = 'user_connected';
/**
 * @description This events is received when the user sends a specific action to the server. It usually happens when the user takes an action in the game and sends the output/result of that action to the server for processing
 */
const LIVEGAME_GAME_ACTION = 'user_action';

// Game events
const LIVEGAME_ANSWERED_QUESTION = 'answered_question';


/**
 * @description Used to update the game state when the users enters at any random point in the live game
 */
const GAME_UPDATE_STATE = 'UPDATE_GAME_STATE';

/**
 * @description Show question with a given <b>ID</b> in the game
 */
const GAME_SHOW_QUESTION = 'GAME.SHOW_QUESTION';
/**
 * @description Hide question(s) from the game view
 */
const GAME_HIDE_QUESTION = 'GAME.HIDE_QUESTION';
/**
 * @description Show the intermediate results for a question. When all users finish answering, we show a list of the questions answered for each item.
 */
const GAME_SHOW_RESULT = 'GAME.SHOW_RESULT';

/**
 * @description Show the intermediate results for a question. When all users finish answering, we show a list of the questions answered for each item.
 */
const GAME_HIDE_RESULT = 'GAME.HIDE_RESULT';

/**
 * @description Stop the game -> terminate the current game action
 */
const GAME_STOP_GAME = 'GAME.STOP_GAME';
/**
 * @description Start game -> should be used to start the game automatically from somewhere
 */
const GAME_START_GAME = 'GAME.START_GAME';
/**
 * @description Sets the countdown to the next game
 */
const GAME_SET_COUNTDOWN = 'GAME.SET_COUNTDOWN';
/**
 * @description Updates the countdown to the next game
 */
const GAME_UPDATE_COUNTDOWN = 'GAME.UPDATE_COUNTDOWN';

/**
 * @description Sets the timestamp when the next game begins
 */
const GAME_NEXT_GAME_TIMESTAMP = 'GAME.NEXT_GAME_TIMESTAMP';

/**
 * @description Updates the results object when a user answers a question
 */
const GAME_UPDATED_RESULTS = 'GAME.UPDATED_RESULTS'

/**
 * @description Start playing a video
 */
const LIVEVIDEO_PLAY_ACTION = 'VIDEO.PLAY_ACTION';
/**
 * @description Pause a video
 */
const LIVEVIDEO_PAUSE_ACTION = 'VIDEO.PAUSE_ACTION';
/**
 * @description Load video – insert it into the document to start buffering
 */
const LIVEVIDEO_LOAD_ACTION = 'VIDEO.LOAD_ACTION';
/**
 * @description Change video URL to switch to another video
 */
const LIVEVIDEO_CHANGE_ACTION = 'VIDEO.CHANGE_URL';

const LIVEVIDEO_SEEK_ACTION = 'VIDEO.SEEK_ACTION';

export {
	// Socket actions
	SOCKET_CONNECT,
	SOCKET_DISCONNECT,
	SOCKET_TIMEOUT,
	SOCKET_NUMBER_OF_PLAYERS,
	SOCKET_GAME_STATE,
	// Live game actions
	LIVEGAME_USER_CONNECTED,
	LIVEGAME_GAME_ACTION,
	LIVEGAME_ANSWERED_QUESTION,
	// Live game events
	GAME_SHOW_QUESTION,
	GAME_HIDE_QUESTION,
	GAME_SHOW_RESULT,
	GAME_HIDE_RESULT,
	GAME_STOP_GAME,
	GAME_START_GAME,
	GAME_SET_COUNTDOWN,
	GAME_UPDATE_COUNTDOWN,
	GAME_NEXT_GAME_TIMESTAMP,
	GAME_UPDATED_RESULTS,
	GAME_UPDATE_STATE,
	// Live game video events
	LIVEVIDEO_PLAY_ACTION,
	LIVEVIDEO_PAUSE_ACTION,
	LIVEVIDEO_LOAD_ACTION,
	LIVEVIDEO_CHANGE_ACTION,
	LIVEVIDEO_SEEK_ACTION,
}
