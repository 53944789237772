import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';

import Footer from './FooterComponent';

import {
	authIsAuthenticated
} from '../../auth/store/selectors';

const stateToProps = state => ({
	authIsAuthenticated: authIsAuthenticated(state),
});

@connect(stateToProps, null)
class FooterContainer extends Component {
	render() {
		return (
			<Footer authIsAuthenticated={this.props.authIsAuthenticated} />
		);
	}
}

export default FooterContainer;
