import React, {Component} from 'react';
import {connect} from 'react-redux';
import getText from 'AppUtils/language';
import { getValue } from 'AppUtils/objects';

import Password from './PasswordComponent';
import { authUuid } from "../../../../../auth/store/selectors";
import { apiPost } from 'AppUtils/api';
import { appGetConfig } from "../../../../../../store/selectors";
import { uiRedirect, uiToggleModal } from "../../../../../../store/actions";
import { userUnsubscribe } from "../../../../../user/store/selectors";
import { userLoadInfo, userLoadUnsubscribe } from "../../../../../user/store/actions";
import { authSet } from "../../../../../auth/store/actions";

const stateToProps = state => ({
	authUuid: authUuid(state),
	appGetConfig: appGetConfig(state),
	userUnsubscribe: userUnsubscribe(state),
});

const actionsToProps = dispatch => ({
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	userLoadUnsubscribe: () => dispatch(userLoadUnsubscribe()),
	authSet: (payload) => dispatch(authSet(payload)),
	userLoadInfo: (payload) => dispatch(userLoadInfo(payload)),
});

@connect(stateToProps, actionsToProps)
class PasswordContainer extends Component {

	state = {
		formData: {
			password: {
				element: 'input',
				value: '',
				config: {
					id: 'password',
					name: 'password',
					type: 'password',
					placeholder: getText('unsubscribe[input_password]')
				},
				validation: {
					required: true,
					minlength: this.props.pin.minLength || 4,
				},
				valid: false,
				touched: false,
				validationMessage: ''
			},
		}
	}

	handleInputChange = (element) => {
		const newFormdata = {
			...this.state.formData
		}
		const newElement = {
			...newFormdata[element.event.target.id]
		}
		newElement.value = element.event.target.value;

		let validData = this.validate(newElement);
		newElement.valid = validData[0];
		newElement.validationMessage = validData[1];

		newElement.touched = element.blur;
		newFormdata[element.event.target.id] = newElement;

		this.setState({
			formData: newFormdata
		})
	}

	validate = (element) => {
		let error = [true, ''];

		if(element.validation.numeric) {
			const valid = !isNaN(element.value);
			const message = `${!valid ? getText('validation[numeric]') : ''}`;
			error = !valid ? [valid, message] : error;
		}

		if(element.validation.email) {
			const valid = /\S+@\S+\.\S+/.test(element.value);
			const message = `${!valid ? getText('validation[email]') : ''}`;
			error = !valid ? [valid, message] : error;
		}

		if(element.validation.minlength) {
			const valid = element.value.length >= element.validation.minlength;
			const message = `${!valid ? getText('validation[minlength]').replace('numberChars', element.validation.minlength) : ''}`;
			error = !valid ? [valid, message] : error;
		}

		if(element.validation.required) {
			const valid = element.value.trim() !== '';
			const message = `${!valid ? getText('validation[required]') : ''}`;
			error = !valid ? [valid, message] : error;
		}

		return error;
	}

	handleUserLogin = () => {
		let msisdn = getValue(this.props, 'msisdnValue', ''); //this.state.msisdn
		let password = this.state.formData.password.value; //this.state.password
		let uuid= this.props.authUuid;;

		apiPost('/user/login', { msisdn, password, uuid })
			.catch((e) => {
				this.openModal(getText('global[text_error]'), getText('login[error_upstream_401]'));
				return Observable.empty();
			})
			.mergeMap(res => {
				let resp = Promise.resolve({ error: true });
				if (res.ok) {
					resp = res.json();
				}

				return Promise.resolve({ resp, statusCode: res.status });
			})
			.subscribe((result) => {
				const { resp, statusCode } = result;

				resp.then(response => {
					if (response && !response.error) {
						const apikey = response.apikey;

						if (apikey) {
							this.props.authSet({ apikey });

							// now we have an apikey yey... load info
							this.props.userLoadInfo({ apikey });

							//load event
							this.openModal('', getText('unsubscribe[text_confirm]'), true, getText('unsubscribe[button_yes]'),() => {
								this.props.uiToggleModal({}, { toggle: false }, {} );
								this.props.userLoadUnsubscribe();
							});
						}
					} else {
						let errorMsg = '';
						switch (statusCode) {
							case 400:
								errorMsg = getText('login[error_upstream_400]');
								break;
							case 401:
								errorMsg = getText('login[error_upstream_401]');
								break;
							case 403:
								errorMsg = getText('login[error_upstream_403]');
								break;
							case 404:
								errorMsg = getText('login[error_upstream_404]');
								break;
							case 500:
								errorMsg = getText('login[error_upstream_500]');
								break;
							case 502:
								errorMsg = getText('login[error_upstream_502]');
								break;
							case 503:
								errorMsg = getText('login[error_upstream_503]');
								break;
							default:
								errorMsg = getText('login[error_upstream_401]');
						}
						this.openModal(getText('global[text_error]'), errorMsg);
					}
				});
			});
	};

	handleSubmit = (e) => {
		e.preventDefault();

		const newFormdata = {
			...this.state.formData
		}

		let counterValid = 0;
		Object.keys(newFormdata).forEach((key) => {
			let validData = this.validate(newFormdata[key]);
			newFormdata[key].valid = validData[0];
			newFormdata[key].validationMessage = validData[1];

			if(!newFormdata[key].valid) {
				counterValid++;
			}
		});

		if(counterValid > 0) {
			this.setState({
				formData: newFormdata
			})
		} else {
			this.handleUserLogin();
		}
	}

	openModal = (title, message, button, buttonText, callback) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, callback  } );
	};

	componentDidMount() {
		this.openModal(getText('global[text_success]'), getText('recover[message_success]'));
	}

	render() {
		return (
			<Password
				{...this.state}
				rtl={this.props.rtl}
				pin={this.props.pin}
				handleInputChange={this.handleInputChange}
				handleSubmit={this.handleSubmit}
			/>
		);
	}
}

export default PasswordContainer;
