import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';

import Restricted from './RestrictedComponent';

import { userLoadInfo } from '../../../user/store/actions';

const actionsToProps = dispatch => ({
	userLoadInfo: () => dispatch(userLoadInfo()),
});

@connect(null, actionsToProps)
class RestrictedContainer extends Component {
	render() {
		return (
			<Restricted userLoadInfo={this.props.userLoadInfo} />
		);
	}
}

export default RestrictedContainer;
