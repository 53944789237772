import React, {Component} from 'react';
import {connect} from 'react-redux';

import Auth from './AuthComponent';

import {
	appGetConfig
} from 'AppStore/selectors';
import {appGetInterfaceLang} from '../../../store/selectors';

const stateToProps = state => ({
	appGetConfig: appGetConfig(state),
	lang: appGetInterfaceLang(state),
});

const actionsToProps = dispatch => ({

});

@connect(stateToProps, actionsToProps)
class AuthContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			authType: this.checkAuthType(props.match.url)
		}
	}

	checkAuthType = (url) => {
		let authType = '';

		switch (url) {
			case '/signin':
			case '/signup':
			case '/forgot-password':
			case '/restricted':
				authType = url.replace('/','');
				break;
			default:
				authType = 'signin';
		}

		return authType;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.match.url !== this.props.match.url) {
			this.setState({
				authType: this.checkAuthType(this.props.match.url)
			})
		}
	}

	render() {
		let prefix = this.props.appGetConfig && this.props.appGetConfig.config && this.props.appGetConfig.config.users && this.props.appGetConfig.config.users.msisdn ? this.props.appGetConfig.config.users.msisdn.prefix : false;

		return (
			<Auth
				{...this.state}
				{...this.props}
				rtl={this.props.lang === 'ar' ? true : false}
				prefix={prefix}
			/>
		);
	}
}

export default AuthContainer;
