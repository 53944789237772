import React, { Component } from "react";
import Header from "./HeaderComponent";
import { withRouter } from "react-router-dom";
import connect from 'react-redux/es/connect/connect';

import {
	authIsAuthenticated, authStatus
} from '../../../auth/store/selectors';
import {authLogout} from '../../../auth/store/actions';
import {userInfo, userCredits} from '../../../user/store/selectors';
import {userLoadCredits} from '../../../user/store/actions';

const stateToProps = state => ({
	authIsAuthenticated: authIsAuthenticated(state),
	authStatus: authStatus(state),
	userInfo: userInfo(state),
	userCredits: userCredits(state),
});

const actionsToProps = dispatch => ({
	authLogout: () => dispatch(authLogout()),
	userLoadCredits: () => dispatch(userLoadCredits()),
});

@connect(stateToProps, actionsToProps)
class HeaderContainer extends Component {
	state = {
		menuIsOpen: false
	};

	toggleMenu = () => {
		this.setState({
			menuIsOpen: !this.state.menuIsOpen,
		});
	};

	componentDidMount() {
		// this.props.userLoadCredits();
	}

	render() {
		return (
			<Header
				userInfo={this.props.userInfo}
				userCredits={this.props.userCredits}
				menuIsOpen={this.state.menuIsOpen}
				toggleMenu={this.toggleMenu}
				location={this.props.location.pathname}
				authIsAuthenticated={this.props.authIsAuthenticated}
				authStatus={this.props.authStatus}
				authLogout={this.props.authLogout}
			/>
		);
	}
}

export default withRouter(HeaderContainer);
