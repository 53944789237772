import React, { Component, Profiler } from "react";
import {connect} from 'react-redux';
import getText from "AppUtils/language";

import Profile from "./ProfileComponent";

import {
	appGetConfig
} from 'AppStore/selectors';
import { uiToggleModal } from 'AppStore/actions';
import { userLoadUpdateInfo } from '../user/store/actions';
import {userMsg, userUpdateInfo} from '../user/store/selectors';
import {uiRedirect} from '../../store/actions';

const stateToProps = state => ({
	appGetConfig: appGetConfig(state),
	userMsg: userMsg(state),
	userUpdateInfo: userUpdateInfo(state)
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	userLoadUpdateInfo: (payload) => dispatch(userLoadUpdateInfo(payload)),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
});

@connect(stateToProps, actionsToProps)
class ProfileContainer extends Component {
	state = {
		formData: {
			nickname: {
				element: "input",
				value: '',
				config: {
					id: "nickname",
					name: "nickname",
					type: "text",
					placeholder: getText("profile[text_nickname]"),
				},
				validation: {
					required: true,
					maxlength: 15,
				},
				valid: false,
				touched: false,
				validationMessage: "",
			},
			avatar: {
				element: "input",
				value: '',
				config: {
					id: "avatar",
					name: "avatar",
					type: "radio",
				},
				validation: {
					required: true,
					maxlength: false,
				},
				valid: false,
				validationMessage: "",
			},
		},
	};

	validate = (element) => {
		let error = [true, ""];

		if (element.validation.maxlength) {
			const valid = element.value.length <= element.validation.maxlength;
			const message = `${
				!valid
					? `Must be lower than ${element.validation.maxlength}`
					: ""
			}`;
			error = !valid ? [valid, message] : error;
		}

		if (element.validation.required) {
			const valid = element.value.trim() !== "";
			const message = `${!valid ? getText('validation[required]') : ""}`;
			error = !valid ? [valid, message] : error;
		}

		return error;
	};

	handleInputChange = (element) => {
		const newFormdata = {
			...this.state.formData,
		};

		const newElement = {
			...newFormdata[element.event.target.name]
		}

		newElement.value = element.event.target.value;

		let validData = this.validate(newElement);
		newElement.valid = validData[0];
		newElement.validationMessage = validData[1];

		newElement.touched = element.blur;
		newFormdata[element.event.target.name] = newElement;

		this.setState({
			formData: newFormdata,
		});
	};

	handleSubmit = (e) => {
		e.preventDefault();

		const newFormdata = {
			...this.state.formData,
		};

		let counterValid = 0;
		Object.keys(newFormdata).forEach((key) => {
			let validData = this.validate(newFormdata[key]);
			newFormdata[key].valid = validData[0];
			newFormdata[key].validationMessage = validData[1];

			if (!newFormdata[key].valid) {
				counterValid++;
			}
		});

		if (counterValid > 0) {
			this.setState({
				formData: newFormdata,
			});
		} else {
			this.props.userLoadUpdateInfo({ settings: `{"nickname": "${this.state.formData.nickname.value}", "avatar": "${this.state.formData.avatar.value}"}` });
		}
	};

	openModal = (title, message, button, buttonText, callback) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, callback  } );
	};

	componentDidMount() {
		this.setState({
			formData: {
				...this.state.formData,
				nickname: {
					...this.state.formData.nickname,
					value: this.props.userInfo.settings.nickname || `User${this.props.userInfo.id}` || `User${Math.floor(Math.random() * 1000)}`
				},
				avatar: {
					...this.state.formData.avatar,
					value: this.props.userInfo.settings.avatar || ''
				}
			}
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const previousNickname = prevProps.userInfo.settings.nickname;

		if((prevProps.userInfo.settings.nickname !== this.props.userInfo.settings.nickname) && typeof prevProps.userInfo.settings.nickname === 'undefined') {
			this.props.uiRedirect({
				pathname: '/play'
			})
		}

		if(prevProps.userMsg !== this.props.userMsg && this.props.userMsg) {
			let errorMsg = this.props.userMsg;
			if(this.props.userMsg === 'NICKNAME_ALREADY_CHOSEN') {
				errorMsg = getText('profile[error_user_exists]');
			}
			if(this.props.userMsg) {
				this.openModal('Error', errorMsg);
			}
		}

		if(prevProps.userUpdateInfo !== this.props.userUpdateInfo) {
			// if(prevProps.userInfo.settings.nickname !== this.props.userInfo.settings.nickname) {
			// 	this.setState({
			// 		formData: {
			// 			...this.state.formData,
			// 			nickname: {
			// 				...this.state.formData.nickname,
			// 				value: this.props.userInfo.settings.nickname
			// 			},
			// 			avatar: {
			// 				...this.state.formData.avatar,
			// 				value: this.props.userInfo.settings.avatar
			// 			}
			// 		}
			// 	})
			// }

			if(this.props.userUpdateInfo) {
				if(!previousNickname) {
					this.props.uiRedirect({
						pathname: '/play'
					})
				} else {
					this.openModal(getText('global[text_success]'), getText("profile[text_success]"));
				}
			}
		}
	}

	render() {
		return (
			<div>
				<Profile
					{...this.state}
					appGetConfig={this.props.appGetConfig}
					handleInputChange={this.handleInputChange}
					handleSubmit={this.handleSubmit}
					handleAvatarChange={this.handleAvatarChange}
				/>
			</div>
		);
	}
}

export default ProfileContainer;
