import React, { Component } from 'react';
import {connect} from 'react-redux';
import UnsubscribePrompt from './UnsubscribePromptComponent';
import { authUuid } from "../../../../auth/store/selectors";
import { apiPost } from 'AppUtils/api';
import { uiToggleModal } from "../../../../../store/actions";
import {appGetInterfaceLang} from '../../../../../store/selectors';

const stateToProps = state => ({
	authUuid: authUuid(state),
	lang: appGetInterfaceLang(state),
});

const actionsToProps = dispatch => ({
});

@connect(stateToProps, actionsToProps)
class UnsubscribePromptContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			confirmPass: false,
			msisdn: null
		}
	}

	nextStep = () => {
		this.setState({ confirmPass: true });
	};

	setMsisdn = (msisdn) => {
		this.setState({ msisdn });
	};

	render() {
		return (
			<UnsubscribePrompt
				confirmPass={this.state.confirmPass}
				msisdnValue={this.state.msisdn}
				msisdn={this.props.msisdn}
				pin={this.props.pin}
				nextStep={this.nextStep}
				setMsisdn={this.setMsisdn}
				rtl={this.props.lang === 'ar' ? true : false}
			/>
		);
	}
}

export default UnsubscribePromptContainer;
