import React from 'react';

import Quiz from '../subcomponents/quiz/Quiz';
import MemoryGame from '../subcomponents/memory-game/MemoryGame';

import './GameDetails.scss';
import RealtimeQuiz from '../subcomponents/realtime-quiz/RealtimeQuiz';

const GameDetailsComponent = (props) => {
	if(props.playGetGame || props.isLiveGame) {
		return (
			<div className="game-details-component vertical-center">
				{	props.gameType === 'live-quiz' ?
					<RealtimeQuiz gameType={props.gameType} playGetGame={props.playGetGame} userInfo={props.userInfo} playStartGame={props.playGetGame} lang={props.lang} uiRedirect={props.uiRedirect} />
					:
					props.gameId === 1 ?
					<Quiz playGetGame={props.playGetGame} playStartGame={props.playStartGame} lang={props.lang} uiRedirect={props.uiRedirect} />
					:
					props.gameId === 2 ?
					<MemoryGame playGetGame={props.playGetGame} playStartGame={props.playStartGame} lang={props.lang} uiRedirect={props.uiRedirect} />
					:
					null
				}
			</div>
		);
	} else {
		return null;
	}

};

export default GameDetailsComponent;
