// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/icon-completed.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".games-slider .slick-list {\n  box-sizing: initial;\n  padding-top: 16px !important;\n  padding-bottom: 16px !important; }\n\n.games-slider .slick-slide {\n  padding: 0 7.5px;\n  box-sizing: border-box; }\n\n.games-slider .slick-dots {\n  bottom: -17px; }\n\n.games-slider .banner .a {\n  display: block;\n  position: relative;\n  padding-bottom: 50%;\n  background-color: transparent;\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: center;\n  box-shadow: none; }\n  .games-slider .banner .a .title-game {\n    position: absolute;\n    left: 6.46%;\n    top: 10.33%;\n    width: 44%; }\n    .games-slider .banner .a .title-game h5 {\n      margin: 0;\n      text-align: left;\n      font-size: 24px;\n      line-height: 26px;\n      font-weight: 400;\n      color: #3f3f40; }\n    .games-slider .banner .a .title-game h6 {\n      margin-top: 15px;\n      margin-bottom: 0;\n      text-align: left;\n      font-size: 14px;\n      line-height: 14px;\n      font-weight: 400;\n      color: #3f3f40; }\n  .games-slider .banner .a p {\n    position: absolute;\n    left: 6.46%;\n    bottom: 12.91%;\n    margin: 0;\n    text-align: center;\n    font-size: 14px;\n    text-transform: uppercase;\n    line-height: 1;\n    font-weight: 500;\n    color: #3f3f40;\n    padding: 10px;\n    padding-left: 0;\n    letter-spacing: -0.3px;\n    border-radius: 30px; }\n    .games-slider .banner .a p.completed::before {\n      background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n      display: inline-block;\n      content: '';\n      width: 20px;\n      height: 20px;\n      background-size: 100%;\n      top: 5px;\n      position: relative;\n      margin-right: 4px; }\n\n@media (max-width: 345px) {\n  .games-slider .item a {\n    background-size: 50%; }\n    .games-slider .item a .title-game h5 {\n      font-size: 22px;\n      line-height: 24px; }\n    .games-slider .item a p {\n      font-size: 12px; } }\n", ""]);
// Exports
module.exports = exports;
