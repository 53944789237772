import React from 'react';
import getText, { HtmlParser } from 'AppUtils/language';

import "../TextPages.scss";

const FaqComponent = (props) => {
    return (
        <div className="text-pages faq-component">
            <h1>{getText("faq[text_title]")}</h1>
			<HtmlParser html="faq[text_content]" />
        </div>
    );
}

export default FaqComponent;
