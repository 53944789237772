import socketIOClient from 'socket.io-client';
/**
 * Socket handler class
 */

class SocketConnection {
	/**
	 * @desc Singleton instance of the SocketConnection class
	 */
	static instance;

	socket;

	/**
	 * @desc Constructor
	 * @returns {*}
	 */
	constructor() {
		if(SocketConnection.instance){
			console.log("Returning existing instance", process.env.REACT_APP_WS_HOST);
			return SocketConnection.instance;
		}

		SocketConnection.instance = this;
		console.log("Creating new socket.io instance", process.env.REACT_APP_WS_HOST);
		this.socket = socketIOClient(process.env.REACT_APP_WS_HOST);
	}

	/**
	 * @desc On event
	 * @param event
	 * @param callback
	 */
	on(event, callback){
		if( ! this.socket.connected ){
			this.socket.connect();
			window.setTimeout(() => {
				this.socket.on(event, callback);
			}, 1000);
		} else {
			this.socket.on(event, callback);
		}

	}

	/**
	 * @desc Emit event
	 * @param event
	 * @param data
	 */
	emit(event, data){
		this.socket.emit(event, data);
	}
}

export default SocketConnection;
