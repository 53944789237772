import React from 'react';
import getText from 'AppUtils/language';

import '../../../auth/subcomponents/signup/subcomponents/text/Text.scss';

const UnsubscribeTextComponent = (props) => {
	return (
		<div className="unsubscribe-text-component text-component">
			<div className="inputs-wrapper">
				<p>{getText('unsubscribe[text_unsubscribe]')}</p>
			</div>
		</div>
	);
};

export default UnsubscribeTextComponent;
