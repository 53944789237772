// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/icon-locker.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "nav {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n  margin-left: -100%;\n  transition: margin-left .2s ease-in;\n  z-index: 200; }\n  nav.active {\n    margin-left: 0; }\n    nav.active .nav-inner {\n      overflow-y: auto; }\n      nav.active .nav-inner ul {\n        left: 0;\n        opacity: 1; }\n  nav .nav-inner {\n    height: 100%;\n    width: 100%;\n    background-color: #e6e6e6; }\n    nav .nav-inner ul {\n      position: relative;\n      left: -50px;\n      padding-top: 100px;\n      text-align: center;\n      opacity: 0;\n      transition: all .2s ease-in;\n      transition-delay: .2s; }\n      nav .nav-inner ul li {\n        padding: 2% 0; }\n        nav .nav-inner ul li.restricted a {\n          display: inline-block;\n          background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n          background-repeat: no-repeat;\n          background-position: left center;\n          background-size: 16px 20px;\n          padding-left: 20px;\n          opacity: .4; }\n        nav .nav-inner ul li a {\n          display: inline-block;\n          font-size: 24px;\n          line-height: 1.35;\n          text-decoration: none;\n          color: #3f3f40; }\n\n@media (max-height: 574px) {\n  nav .nav-inner ul {\n    padding-top: 58px; }\n    nav .nav-inner ul li {\n      padding: 1.5% 0; } }\n", ""]);
// Exports
module.exports = exports;
