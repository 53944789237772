import React from 'react';
import { Link } from 'react-router-dom';
import getText from 'AppUtils/language';

import FormField from '../../../../common/form-field/FormFieldComponent';
import Button from '../../../../common/button/ButtonComponent';

import './Signin.scss';

const SigninComponent = (props) => {
	return (
		<div className="signin-component">
			<form onSubmit={(e) => props.handleSubmit(e)}>
				<div className="inputs-wrapper">
					<div className="shadow-wrapper">
						<FormField
							{...props.formData.phone}
							handleChange={(element) => props.handleInputChange(element)}
							validationMessage={props.formData.phone.validationMessage}
							prefix={props.prefix}
							maxLength={props.msisdn.maxLength ? props.msisdn.maxLength : false}
							rtl={props.rtl}
						/>
						<FormField
							{...props.formData.password}
							handleChange={(element) => props.handleInputChange(element)}
							validationMessage={props.formData.password.validationMessage}
							maxLength={props.pin.maxLength ? props.pin.maxLength : false}
							rtl={props.rtl}
						/>
					</div>
				</div>
				<button
					type="submit"
				>
					<Button
						fullWidth={true}
						boxShadow={true}
					>
						{getText('login[button_login]')}
					</Button>
				</button>
			</form>
			<div className="btn-actions">
				<Link to="/signup">
					<Button
						outline={true}
					>
						{getText('login[button_register]')}
					</Button>
				</Link>
				<Link to="/forgot-password">
					<Button
						outline={true}
					>
						{getText('login[button_recover]')}
					</Button>
				</Link>
			</div>
		</div>
	);
};

export default SigninComponent;
