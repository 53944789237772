import React, { Component } from 'react';

import Faq from "./FaqComponent";

class FaqContainer extends Component {

    render() {
        return (
			<Faq />
        );
    }
}

export default FaqContainer;
