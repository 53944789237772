import React, {Component} from 'react';
import Observable from 'Observable';
import getText from 'AppUtils/language';
import {connect} from 'react-redux';
import { getValue } from 'AppUtils/objects';

import Msisdn from './MsisdnComponent';
import { uiToggleModal } from 'AppStore/actions';
import { authUuid } from "../../../../../auth/store/selectors";
import { apiPost } from 'AppUtils/api';

const stateToProps = state => ({
	authUuid: authUuid(state),
});

const actionsToProps = dispatch => ({
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
});

@connect(stateToProps, actionsToProps)
class MsisdnContainer extends Component {

	state = {
		formData: {
			phone: {
				element: 'input',
				value: '',
				config: {
					id: 'phone',
					name: 'phone',
					type: 'tel',
					placeholder: getText('unsubscribe[input_username]')
				},
				validation: {
					required: true,
					minlength: this.props.msisdn.minLength || 4,
					numeric: true
				},
				valid: false,
				touched: false,
				validationMessage: ''
			},
		}
	}

	handleInputChange = (element) => {
		const newFormdata = {
			...this.state.formData
		}
		const newElement = {
			...newFormdata[element.event.target.id]
		}
		newElement.value = element.event.target.value;

		let validData = this.validate(newElement);
		newElement.valid = validData[0];
		newElement.validationMessage = validData[1];

		newElement.touched = element.blur;
		newFormdata[element.event.target.id] = newElement;

		this.setState({
			formData: newFormdata
		})
	}

	validate = (element) => {
		let error = [true, ''];

		if(element.validation.numeric) {
			const valid = !isNaN(element.value);
			const message = `${!valid ? getText('validation[numeric]') : ''}`;
			error = !valid ? [valid, message] : error;
		}

		if(element.validation.email) {
			const valid = /\S+@\S+\.\S+/.test(element.value);
			const message = `${!valid ? getText('validation[email]') : ''}`;
			error = !valid ? [valid, message] : error;
		}

		if(element.validation.minlength) {
			const valid = element.value.length >= element.validation.minlength;
			const message = `${!valid ? getText('validation[minlength]').replace('numberChars', element.validation.minlength) : ''}`;
			error = !valid ? [valid, message] : error;
		}

		if(element.validation.required) {
			const valid = element.value.trim() !== '';
			const message = `${!valid ? getText('validation[required]') : ''}`;
			error = !valid ? [valid, message] : error;
		}

		return error;
	}

	openModal = (title, message, button, buttonText, callback) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, callback  } );
	};

	handleUserPrompt = () => {
		let uuid = this.props.authUuid;
		let msisdn = this.state.formData.phone.value;
		let prefix = getValue(this.props, 'msisdn.prefix', ''); //this.state.msisdn
		if(prefix) {
			msisdn = `${prefix.replace('+', '')}${msisdn}`;
		}

		apiPost('/user/remind-password', { msisdn, uuid })
			.catch((e) => {
				this.openModal(getText('eroare'), getText('recover[error_upstream_3]'));

				return Observable.empty();
			})
			.mergeMap(res => {
				let resp = Promise.resolve({ error: true });
				if (res.ok) {
					resp = res.json();
				}

				return Promise.resolve({ resp, statusCode: res.status });
			})
			.subscribe((result) => {
				const { resp, statusCode } = result;

				resp.then(response => {
					if (response && !response.error) {
						this.props.setMsisdn(msisdn);
						this.props.nextStep();
					} else {
						let errorMsg = '';
						switch (statusCode) {
							case 400:
								errorMsg = getText('recover[error_upstream_400]');
								break;
							case 401:
								errorMsg = getText('recover[error_upstream_401]');
								break;
							case 403:
								errorMsg = getText('recover[error_upstream_403]');
								break;
							case 404:
								errorMsg = getText('recover[error_upstream_404]');
								break;
							case 500:
								errorMsg = getText('recover[error_upstream_500]');
								break;
							case 502:
								errorMsg = getText('recover[error_upstream_502]');
								break;
							case 503:
								errorMsg = getText('recover[error_upstream_503]');
								break;
							default:
								errorMsg = getText('recover[error_upstream_401]');
						}
						this.openModal(getText('global[text_error]'), errorMsg);
					}
				});
			});
	};

	handleSubmit = (e) => {
		e.preventDefault();

		const newFormdata = {
			...this.state.formData
		}

		let counterValid = 0;
		Object.keys(newFormdata).forEach((key) => {
			let validData = this.validate(newFormdata[key]);
			newFormdata[key].valid = validData[0];
			newFormdata[key].validationMessage = validData[1];

			if(!newFormdata[key].valid) {
				counterValid++;
			}
		});

		if(counterValid > 0) {
			this.setState({
				formData: newFormdata
			})
		} else {
			this.handleUserPrompt();
		}
	}

	render() {
		let prefix = this.props.msisdn.prefix;

		if(this.props.lang === 'ar') {
			prefix = prefix.split('').reverse().join('');
		}

		return (
			<Msisdn
				{...this.state}
				rtl={this.props.rtl}
				msisdn={this.props.msisdn}
				prefix={prefix}
				handleInputChange={this.handleInputChange}
				handleSubmit={this.handleSubmit}
			/>
		);
	}
}

export default MsisdnContainer;
