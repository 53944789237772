// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/level-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".player-stats-component {\n  position: absolute;\n  top: 7px;\n  right: 0;\n  height: 40px;\n  text-align: right;\n  display: flex;\n  align-items: center;\n  z-index: 1; }\n  .player-stats-component::before {\n    content: \"\";\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 0;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); }\n  .player-stats-component .stats-item {\n    display: flex;\n    align-items: center;\n    z-index: 1; }\n    .player-stats-component .stats-item .stats-avatar {\n      display: inline-block;\n      height: 44px;\n      width: 44px; }\n    .player-stats-component .stats-item .stats-info {\n      margin-right: 10px;\n      margin-left: 5px; }\n      .player-stats-component .stats-item .stats-info span {\n        display: block;\n        font-size: 18px;\n        line-height: 21px;\n        color: #3f3f40;\n        text-transform: uppercase; }\n        .player-stats-component .stats-item .stats-info span.small {\n          font-size: 8px;\n          line-height: 10px; }\n", ""]);
// Exports
module.exports = exports;
