import React, {Component} from 'react';
import clsx from 'clsx';
import Timer from 'react-compound-timer'
import {
	CSSTransition,
	TransitionGroup,
} from 'react-transition-group';
import getText from 'AppUtils/language';

import './RealtimeQuiz.scss';
import '../Timer.scss';

import iconTimer from '../../main/img/icon-timer.png';
import SocketConnection from '../../../../../utils/socket';
import {
	GAME_HIDE_QUESTION, GAME_HIDE_RESULT,
	GAME_SHOW_QUESTION, GAME_SHOW_RESULT,
	GAME_START_GAME, GAME_STOP_GAME,
	GAME_UPDATE_COUNTDOWN, GAME_UPDATE_STATE, LIVEGAME_ANSWERED_QUESTION, LIVEGAME_GAME_ACTION,
	LIVEGAME_USER_CONNECTED,
	LIVEVIDEO_PLAY_ACTION, LIVEVIDEO_SEEK_ACTION, SOCKET_DISCONNECT,
} from '../types';
import Vimeo from '@u-wave/react-vimeo';

const socket = new SocketConnection();

const withTimer = timerProps => WrappedComponent => wrappedComponentProps => (
	<Timer {...timerProps}>
		{timerRenderProps =>
			<WrappedComponent {...wrappedComponentProps} timer={timerRenderProps}/>}
	</Timer>
);

class RealtimeQuiz extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			connectedUsers: 0,
			socket: socket,
			gameStarted: false,
			gameEnded: true,
			currentQuestion: null, // This is the current question
			lastQuestionId: null,
			current: null, // This is the current answer of the user
			hasVideo: false,
			videoUrl: '',
			correct: 0,
			incorrect: 0,
			answerChoice: null,
			allChoices: {},
			questionResults: null,
			nextGameAt: ["00", "00", "00"],
			currentUserPoints: 0,
			videoCurrentTime: 0,
			wonUserPoints: 0,
			pointsPerCorrectAnswer: 0,
			pointsPerIncorrectAnswer: 0,
			latestSeekTime: 0,
			socketGameId: -1,
			isSocketConnected: socket !== undefined && socket.connected
		}

		this.handleClick = this.handleClick.bind(this)
		this.redirectToLeaderboard = this.redirectToLeaderboard.bind(this);
		this.playMiniQuiz = this.playMiniQuiz.bind(this);
		this.aboutRedirect = this.aboutRedirect.bind(this);
		this.logoutRedirect  = this.logoutRedirect.bind(this);
		this.setLatestSeekTime = this.setLatestSeekTime.bind(this);
		this.animatePointsCountdown = this.animatePointsCountdown.bind(this);

	} // end constructor

	handleClick(choice) {
		// Update game state with the current user choice
		if (choice === this.state.currentQuestion.correct) {
			let newDataSet = this.state.currentQuestion;
			newDataSet.touched = true;

			let allChoices = {...this.state.allChoices};
			allChoices[newDataSet.id] = newDataSet.answers[choice].id;

			this.setState({
				correct: this.state.correct + 1,
				answerChoice: choice,
				currentQuestion: newDataSet,
				allChoices,
				wonUserPoints: this.state.wonUserPoints + this.state.pointsPerCorrectAnswer,
			})
		} else {
			let newDataSet = this.state.currentQuestion;
			newDataSet.touched = true;

			let allChoices = {...this.state.allChoices};
			allChoices[newDataSet.id] = newDataSet.answers[choice] ? newDataSet.answers[choice].id : null;

			this.setState({
				incorrect: this.state.incorrect + 1,
				answerChoice: choice,
				currentQuestion: newDataSet,
				allChoices,
				wonUserPoints: this.state.wonUserPoints + this.state.pointsPerIncorrectAnswer,
			});
		}

		// Dispatch the user choice on the socket
		const socket_action_payload = {
			action: LIVEGAME_ANSWERED_QUESTION,
			meta: {
				game_id: this.state.game_id,
				game_session_id: this.props.playGetGame.id,
				user_id: this.props.userInfo.id,
				question_id: this.state.lastQuestionId,
				answer_id: this.state.currentQuestion.answers[choice].id,
			},
		}
		this.state.socket.emit(LIVEGAME_GAME_ACTION, socket_action_payload);
	}

	animatePointsCountdown(start, end, duration) {
		if(start >= end) return;

		let range = end - start;
		let current = start;
		let increment = end > start? 1 : -1;
		let stepTime = Math.abs(Math.floor(duration / range));
		let timer = setInterval(() => {

			current += increment;

			this.setState({
				currentUserPoints: current
			});
			if (current == end) {
				clearInterval(timer);
			}
		}, stepTime);
	}

	componentWillUnmount() {
		// Remove the background for the game
		document.getElementById('root').classList.remove("bg-live-game");
		this.setState({
			wonUserPoints: 0,
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevState.socketGameId !== this.state.socketGameId && this.state.socketGameId === this.props.playGetGame.id){
			/**
			 * @desc
			 */
			this.state.socket.on(GAME_SHOW_QUESTION, (data) => {
				let question = data;

				data.answers.forEach((answer, index) => {
					if(answer.correct === true){
						question.correct = index;
						return;
					}
				});

				this.setState({
					currentQuestion: question,
					lastQuestionId: question.question_id,
					connectedUsers: data._liveUsersCount,
				});

				this.props.timer.setCheckpoints([
					{
						time: 0,
						callback: () => {
						},
					},
				]);
				this.props.timer.setTime(question.duration * 1000 + 500);
				this.props.timer.start();
			});
			/**
			 * @desc
			 */
			this.state.socket.on(GAME_HIDE_QUESTION, (data) => {
				this.setState({
					currentQuestion: null,
				})
			});
			/**
			 * @desc
			 */
			this.state.socket.on(GAME_SHOW_RESULT, (data) => {
				console.log(data, "GAME_SHOW_RESULT");
				if(data.questions !== undefined){
					const result = data.questions.filter(question => question.id === this.state.lastQuestionId);
					if(result.length > 0) {
						this.setState({
							questionResults: result[0],
							connectedUsers: data._liveUsersCount,
							currentQuestion: null
						});
					} else {
						this.setState({
							questionResults: null,
							connectedUsers: data._liveUsersCount,
							currentQuestion: null
						})
					}
				} else {
					this.setState({
						questionResults: null,
						connectedUsers: data._liveUsersCount,
						currentQuestion: null
					})
				}
			});
			/**
			 * @desc
			 */
			this.state.socket.on(GAME_HIDE_RESULT, (data) => {
				this.setState({
					questionResults: null,
					connectedUsers: data._liveUsersCount,
				})
			});
			/**
			 * @desc
			 */
			this.state.socket.on(GAME_STOP_GAME, (data) => {
				this.setState({
					gameStarted: false,
					gameEnded: true,
					currentQuestion: null, // This is the current question
					lastQuestionId: null,
					current: null, // This is the current answer of the user
					hasVideo: false,
					videoUrl: '',
					correct: 0,
					incorrect: 0,
					answerChoice: null,
					allChoices: {},
					questionResults: null,
					nextGameAt: ["00", "00", "00"],
					currentUserPoints: this.props.userInfo.points,
					videoCurrentTime: 0,
					pointsPerCorrectAnswer: 0,
					pointsPerIncorrectAnswer: 0,
					latestSeekTime: 0,
				});

				setTimeout(() => {
					let element = document.getElementById('pointsLabel');
					if(element){
						element.classList.add('won-points');
						let nextUserPoints = this.state.currentUserPoints + this.state.wonUserPoints;
						let currentUserPoints = this.state.currentUserPoints;
						this.animatePointsCountdown(currentUserPoints, nextUserPoints, 3000)
						setTimeout(() => {
							element.classList.remove('won-points');
						}, 3000);
					}
				}, 1000);
			});

			/**
			 * @desc
			 */
			this.state.socket.on(LIVEVIDEO_PLAY_ACTION, (data) => {
				this.setState({
					videoUrl: data.url,
					hasVideo: data.hasVideo,
					connectedUsers: data._liveUsersCount,
				});
			});

		}
	}


	componentDidMount() {
		// Update the background for the game
		document.getElementById('root').classList.add("bg-live-game");

		// Set user points
		this.setState({
			currentUserPoints: this.props.userInfo.points,
			isSocketConnected: true
		});

		// Emit user update with the current game play ID
		const payload = {
			...this.props.userInfo,
			token: localStorage.getItem('apikey'),
			gamePlayId: this.props.playStartGame.id,
			currentGameId: this.props.playStartGame.id
		};

		console.log('User connected');
		this.state.socket.emit(LIVEGAME_USER_CONNECTED, payload);


		// Listen to all socket events

		/**
		 * @desc Listen to the number of players socket event
		 */
		// this.state.socket.on(SOCKET_NUMBER_OF_PLAYERS, (response) => {
		// 	this.setState({
		// 		connectedUsers: response.count
		// 	});
		// });

		this.state.socket.on(LIVEVIDEO_SEEK_ACTION, (response) => {
			// Seek video to seconds
			this.setState({
				videoCurrentTime: response.seek,
				connectedUsers: response._liveUsersCount,
			});
		});


		/**
		 * @desc Event received when a game has started
		 */
		this.state.socket.on(GAME_START_GAME, (data) => {

			if(this.state.gameStarted === false){
				const payload = {
					...this.props.userInfo,
					token: localStorage.getItem('apikey'),
					gamePlayId: this.props.playStartGame.id,
					currentGameId: data.game_id,
					gameStarted: true,
					bypassSocketCheck: true
				};
				this.state.socket.emit(LIVEGAME_USER_CONNECTED, payload);
			}
			this.setState({
				gameStarted: true,
				gameEnded: false,
				socketGameId: data.game_id,
				pointsPerCorrectAnswer: data.pointsPerCorrectAnswer,
				pointsPerIncorrectAnswer: data.pointsPerIncorrectAnswer,
				connectedUsers: data._liveUsersCount,
			});
		});

		/**
		 *
		 * @description Received game update state from the WS
		 */
		this.state.socket.on(GAME_UPDATE_STATE, (data) => {
			this.setState({
				latestSeekTime: data.latestSeekTime,
				hasVideo: data.hasVideo,
				videoUrl: data.videoUrl,
				gameStarted: data.gameStarted,
				gameEnded: data.gameEnded,
				socketGameId: data.socketGameId,
				pointsPerCorrectAnswer: data.pointsPerCorrectAnswer,
				pointsPerIncorrectAnswer: data.pointsPerIncorrectAnswer,
				connectedUsers: data._liveUsersCount,
			});
		});

		/**
		 * @desc This event will update the countdown for the current game
		 */
		this.state.socket.on(GAME_UPDATE_COUNTDOWN, (data) => {
			if(data.game_id === this.props.playGetGame.id){
				this.setState({
					// gameStarted: false,
					nextGameAt: data.timestamp
				});
			}
		});

		this.state.socket.on(SOCKET_DISCONNECT, () => {
			console.log('User disconnected');
			this.setState({
				isSocketConnected: false,
			});
		});

	}

	redirectToLeaderboard(){
		this.props.uiRedirect({
			pathname: `/leaderboard`,
		})
	}

	playMiniQuiz(){
		this.props.uiRedirect({
			pathname: `/play`,
		});
	}

	aboutRedirect(){
		this.props.uiRedirect({
			pathname: '/about'
		})
	}

	logoutRedirect(){
		this.props.uiRedirect({
			pathname: '/logout'
		})
	}

	setLatestSeekTime(seekTime){
		this.setState({
			latestSeekTime: seekTime
		});
	}


	render() {
		if (!this.state.isSocketConnected) {
			return (
				<div className="live_quiz_video__disconnected">
					<div className="live_quiz_video__disconnected__label">
						You have been disconnected
					</div>
				</div>
			);
		}
		if (this.state.gameStarted) {
			return (
				<div className={this.state.hasVideo ? 'quiz live_quiz_video' : 'quiz live_quiz_video text-only'}>
					{this.state.connectedUsers !== 0 ?
						<div className="live_quiz_video__num_players">{this.state.connectedUsers}</div>
						:
						null
					}
					<div className="title">
						<h2>{this.props.playGetGame.name[this.props.lang]}</h2>
						<p>{this.props.playGetGame.subtitle[this.props.lang]}</p>
					</div>
					{this.state.hasVideo ?
						<VideoArea videoUrl={this.state.videoUrl} latestSeekTime={this.state.latestSeekTime} setLatestSeekTime={this.setLatestSeekTime} seekTime={this.state.videoCurrentTime}/>
						:
						null
					}
					<ScoreArea correct={this.state.correct} incorrect={this.state.incorrect}/>

					{this.state.currentQuestion === null && this.state.questionResults === null ?
						<div className="live_quiz_video__next__prepare">Get ready for next question</div>
					:
					null
					}

					{this.state.currentQuestion !== null ?
						<div>
							<div className="game-timer">
								<img src={iconTimer} alt=""/> <div className="time-left"><Timer.Minutes formatValue={value => ("0" + value).slice(-2)} />:<Timer.Seconds formatValue={value => ("0" + value).slice(-2)} /></div>
							</div>
							<QuizArea handleClick={this.handleClick} dataSet={this.state.currentQuestion}
								  answerChoice={this.state.answerChoice} lang={this.props.lang}/>
						</div>
						:
						null
					}
					{this.state.questionResults !== null ?
						<div className="live_quiz_video__results">
							<div className="live_quiz_video__results__question">
								{this.state.questionResults.title[this.props.lang]}
							</div>
							<div className="live_quiz_video__results__global_scores">
								<div className="live_quiz_video__results__global_scores__correct">
									Users answered correct
									<br/>
									<span>{this.state.questionResults.correct_user_answers}</span>
								</div>
								<div className="live_quiz_video__results__global_scores__incorrect">
									Users answered incorrect
									<br/>
									<span>{this.state.questionResults.incorrect_user_answers}</span>
								</div>
							</div>
						</div>
						:
						null
					}

				</div>
			)
		} else {
			return (
				this.state.gameEnded === true && this.state.nextGameAt[0] !== "00" ?
					<div className="live_quiz_video__next">
						<div className="live_quiz_video__next__game_logo">
							<img src="/static/live-battle.png" />
						</div>
						<div className="live_quiz_video__next__countdown">
							<div className="live_quiz_video__next__countdown__text_top">
								{getText('games[live_game_in]')}
							</div>
							<div className="live_quiz_video__next__countdown__time_left">
								{/*{this.props.playGetGame.name[this.props.lang]}*/}
								{this.state.nextGameAt}
							</div>
							<div className="live_quiz_video__next__countdown__text_bottom">
								{getText('games[prepare_for_battle]')}
							</div>
						</div>
						<div className="live_quiz_video__next__user_card">
							<div className="live_quiz_video__next__user_card__header">
								<div className="live_quiz_video__next__user_card__header__info">
									<div onClick={this.aboutRedirect} className="live_quiz_video__next__user_card__header__icon">
										<img src="/static/info.png" />
									</div>
								</div>
								<div className="live_quiz_video__next__user_card__header__user_avatar">
									<div className="live_quiz_video__next__user_card__header__user_avatar__name">
										{this.props.userInfo.settings.nickname}
									</div>
									<div className="live_quiz_video__next__user_card__header__user_avatar__image">
										<img src={this.props.userInfo.settings.avatar}/>
									</div>
								</div>
								<div onClick={this.aboutRedirect} className="live_quiz_video__next__user_card__header__logout">
									<div className="live_quiz_video__next__user_card__header__icon">
										<img src="/static/logout.png" />
									</div>
								</div>
							</div>
							<div className="live_quiz_video__next__user_card__body">
								<div className="live_quiz_video__next__user_card__body__item">
									<div className="live_quiz_video__next__user_card__body__item__icon">
										<img src="/static/stars.png" />
									</div>
									<div className="live_quiz_video__next__user_card__body__item__text">
										{getText('header[text_points]')}
									</div>
									<div className="live_quiz_video__next__user_card__body__item__count"
										id="pointsLabel">
										<AnimateNumberPoints points={this.state.currentUserPoints} />
									</div>
									<div onClick={this.redirectToLeaderboard} className="live_quiz_video__next__user_card__body__item__label">
										{getText('top[text_leaderboard]')}
									</div>
								</div>
							</div>
						</div>

						<div className="live_quiz_video__next__missed_button">
							<div className="live_quiz_video__next__missed_button__questionmark">
								<img src="/static/questionmark.png" />
							</div>
							<div className="live_quiz_video__next__missed_button__exclamation">
								<img src="/static/exclamation.png" />
							</div>
							<div onClick={this.playMiniQuiz} className="live_quiz_video__next__missed_button__text">
								<div className="live_quiz_video__next__missed_button__text__small">{getText("games[missed_live_game]")}</div>
								<div className="live_quiz_video__next__missed_button__text__large">{getText("games[game_quiz]")}</div>
							</div>
						</div>
					</div>
					:
					<div className="state-loader">
						<div className="lds-ellipsis">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
			);
		}


	}
}

const TimerHOC = withTimer({
	direction: 'backward',
	initialTime: 10000,
	startImmediately: false,
})(RealtimeQuiz);

<TimerHOC />

function VideoArea(props) {
	const playerRef = React.useRef(null);
	if(playerRef !== null && playerRef.current !== null && props.latestSeekTime !== props.seekTime){
		props.setLatestSeekTime(props.seekTime);
		playerRef.current.getCurrentTime().then((currentPlayerTime) => {
			const diff = currentPlayerTime - props.seekTime;

			if(props.seekTime !== currentPlayerTime && (diff < -1.5 || diff > 1.5)){
				playerRef.current.setCurrentTime(props.seekTime);
				playerRef.current.play();
			}
		});


	}
	if(playerRef !== null && playerRef.current !== null){
		playerRef.current.play();
	}

	return (
		<div className="live_quiz_video__video">
			<Vimeo
				height="200"
				video={props.videoUrl}
				start={props.seekTime}
				controls={false}
				responsive={true}
				autoplay
				onReady={player => {
					playerRef.current = player;
					player.play();
				}}
			/>
		</div>
	);
}


function AnimateNumberPoints(props){
	return (
		<div>{props.points}</div>
	)
}


function Question(props) {
	if(props.dataSet.title === undefined) return null;
	return (
		<h3 className="question">{props.dataSet.title[props.lang]}</h3>
	)
}

function Answer(props) {
	return (
		<CSSTransition
			key={props.id}
			timeout={200 + (200 * (props.index + 1) / 2)}
			classNames="answer-transition"
			in={props.dataSet ? true : false}
			id={props.i}
		>
			<div
				className={clsx({
					answer: true,
					selected: props.choice === props.answerChoice,
					correct: props.choice === props.answerChoice && props.dataSet.touched && props.choice === props.dataSet.correct,
					incorrect: props.choice === props.answerChoice && props.dataSet.touched && props.choice !== props.dataSet.correct
				})}
				style={{transitionDelay: `${200 * (props.index + 1) / 2}ms`}}
			>
				<button onClick={(e) => {
					!props.dataSet.touched ?
						props.handleClick(props.choice)
						:
						e.preventDefault()
				}}>{props.answer[props.lang]}</button>
			</div>
		</CSSTransition>
	)
}

function AnswerList(props) {
	var answers = [];
	for (let i = 0; i < props.dataSet.answers.length; i++) {
		answers.push(
			<Answer key={props.dataSet.answers[i].id} index={i} id={props.dataSet.answers[i].id} choice={i} handleClick={props.handleClick}
					answer={props.dataSet.answers[i].answer} dataSet={props.dataSet} answerChoice={props.answerChoice}
					lang={props.lang}/>
		)
	}
	return (
		<TransitionGroup className="answers-list-transitions">
			<div className="answers-list">
				{answers}
			</div>
		</TransitionGroup>
	)
}

function QuizArea(props) {
	return (
		<div className="quiz-area">
			<Question dataSet={props.dataSet} lang={props.lang}/>
			<AnswerList key={0} dataSet={props.dataSet} handleClick={props.handleClick} answerChoice={props.answerChoice}
						lang={props.lang}/>
		</div>
	)
}

function TotalCorrect(props) {
	var style = {
		display: "inline-block",
		padding: "1em",
		background: "#eee",
		margin: "0 1em 0 0"
	}
	return (
		<h2 style={style}>Correct: {props.correct}</h2>
	)
}

function TotalIncorrect(props) {
	var style = {
		display: "inline-block",
		padding: "1em",
		background: "#eee",
		margin: "0 0 0 1em"
	}
	return (
		<h2 style={style}>Incorrect: {props.incorrect}</h2>
	)
}

function ScoreArea(props) {
	var style = {
		width: "100%",
		display: "flex",
		textAlign: "left",
		padding: "2em"
	}
	return (
		<div style={style} className="live_quiz_video__scorecard">
			<TotalCorrect correct={props.correct}/>
			<TotalIncorrect incorrect={props.incorrect}/>
		</div>
	)
}

export default TimerHOC;
