const cards = [
	"clubs_10.png", "clubs_2.png", "clubs_3.png", "clubs_4.png", "clubs_5.png",
	"clubs_6.png", "clubs_7.png", "clubs_8.png", "clubs_9.png", "clubs_A.png",
	"clubs_J.png", "clubs_K.png", "clubs_Q.png",
	"diamonds_10.png", "diamonds_2.png", "diamonds_3.png", "diamonds_4.png",
	"diamonds_5.png", "diamonds_6.png", "diamonds_7.png", "diamonds_8.png",
	"diamonds_9.png", "diamonds_A.png", "diamonds_J.png", "diamonds_K.png",
	"diamonds_Q.png",
	"hearts_10.png", "hearts_2.png", "hearts_3.png", "hearts_4.png",
	"hearts_5.png", "hearts_6.png", "hearts_7.png", "hearts_8.png",
	"hearts_9.png", "hearts_A.png", "hearts_J.png", "hearts_K.png",
	"hearts_Q.png",
	"spades_10.png", "spades_2.png", "spades_3.png", "spades_4.png",
	"spades_5.png", "spades_6.png", "spades_7.png", "spades_8.png",
	"spades_9.png", "spades_A.png", "spades_J.png", "spades_K.png",
	"spades_Q.png"
];

export default cards;
