import React, { Component } from 'react';
import { HtmlParser } from 'AppUtils/language';

import Terms from "./TermsComponent";

class TermsContainer extends Component {

    render() {
        return (
			<Terms />
        );
    }
}

export default TermsContainer;
