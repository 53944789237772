import * as AUTH from './types';
import ls from 'local-storage';

const defaultState = {
    section: 'auth',
    statusCode: null,
    authenticated: null,
	apikey: ls.get('apikey') || null,
    uuid: ls.get('uuid') || null,
    status: 'guest',// restricted, valid
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
    case AUTH.AUTH_SET: {
        const { apikey = '', status = 'guest', statusCode = '' } = action.payload;

        return {
            ...state,
            authenticated: !!apikey ? true : false,
			apikey,
			status,
			statusCode,
        };
    }
	case AUTH.AUTH_SET_UUID: {
		const { uuid } = action.payload;

		return {
			...state,
			uuid,
		};
	}
    case AUTH.AUTH_UPDATE: {
        const { apikey = '', status = 'guest', statusCode = '' } = action.payload;
        return {
            ...state,
			apikey,
            status,
			statusCode,
        };
    }
    case AUTH.AUTH_RESET:
    	let status = 'guest';
    	if (action && action.payload) {
			status = action.payload.status;
		}

        return {
            ...state,
            authenticated: !!state.apikey ? true : false,
			status,
        };
    default:
        return state;
    }
}
