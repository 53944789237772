import * as USER from './types';

const defaultState = {
	section: 'user',
	msg: null,
	statusCode: null,
	info: null,
	updateInfo: null,
	points: null,
	credits: null,
	leaderBoard: null,
	event: null,
	unsubscribe: null,
};

export default function reducer(state = defaultState, action) {
	switch (action.type) {
		case USER.USER_SET_INFO: {
			const { info, msg, statusCode } = action.payload;

			return {
				...state,
				info,
				msg,
				statusCode,
			};
		}
		case USER.USER_LOAD_UPDATE_INFO: {
			return {
				...state,
				msg: '',
				statusCode: '',
			};
		}
		case USER.USER_SET_UPDATE_INFO: {
			const { updateInfo, msg, statusCode } = action.payload;

			return {
				...state,
				updateInfo,
				msg,
				statusCode,
			};
		}
		case USER.USER_LOAD_UNSUBSCRIBE: {
			return {
				...state,
				unsubscribe: '',
				msg: '',
				statusCode: '',
			};
		}
		case USER.USER_SET_UNSUBSCRIBE: {
			const { unsubscribe, msg, statusCode } = action.payload;

			return {
				...state,
				unsubscribe,
				msg,
				statusCode,
			};
		}
		case USER.USER_SET_POINTS: {
			const { points, msg, statusCode } = action.payload;

			return {
				...state,
				points,
				msg,
				statusCode,
			};
		}
		case USER.USER_LOAD_EVENT: {
			return {
				...state,
				event: null,
			};
		}
		case USER.USER_SET_EVENT: {
			const { event, msg, statusCode } = action.payload;

			return {
				...state,
				event,
				msg,
				statusCode,
			};
		}
		case USER.USER_SET_CREDITS: {
			const { credits, msg, statusCode } = action.payload;

			return {
				...state,
				credits,
				msg,
				statusCode,
			};
		}
		case USER.USER_SET_LEADER_BOARD: {
			const { leaderBoard, msg, statusCode } = action.payload;

			return {
				...state,
				leaderBoard,
				msg,
				statusCode,
			};
		}
		default:
			return state;
	}
}
