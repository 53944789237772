import React from "react";
import getText from "AppUtils/language";

import FormField from "../../common/form-field/FormFieldComponent";
import Button from "../../common/button/ButtonComponent";
import Avatar from "../../common/avatar/AvatarComponent";

import "./Profile.scss";

const ProfileComponent = (props) => {

	const renderAvatars = () => {
		if(props.appGetConfig.avatars) {
			return props.appGetConfig.avatars.map((item, index) => (
				<label htmlFor={item} key={index}>
					<input
						type="radio"
						name="avatar"
						id={item}
						value={item}
						onChange={(event) => props.handleInputChange({event, blur: false})}
						checked={props.formData.avatar.value === item}
					/>
					<Avatar size="80px">
						<img src={item} />
					</Avatar>
				</label>
			));
		}
	};

	// const avatarContext = require.context(
	// 	"../../resources/avatars",
	// 	false,
	// 	/\.(png)$/
	// );

	return (
		<div className="profile-component vertical-center">
			<div className="title">
				<h2>{getText("profile[text_choose_nickname]")}</h2>
				<p>{getText("profile[text_max_chars]")}</p>
			</div>
			<form onSubmit={(e) => props.handleSubmit(e)}>
				<FormField
					{...props.formData.nickname}
					handleChange={(element) => props.handleInputChange(element)}
					validationMessage={props.formData.nickname.validationMessage}
					maxLength={props.formData.nickname.validation.maxlength}
				/>

				<div className="title">
					<h1>{getText("profile[text_choose_avatar]")}</h1>
				</div>

				<div className="avatar-list">
					{renderAvatars()}
				</div>

				<button type="submit">
					<Button
						fullWidth={true}
						boxShadow={true}
					>
						{getText("profile[button_save]")}
					</Button>
				</button>
			</form>
		</div>
	);
};

export default ProfileComponent;
