import React from 'react';
import clsx from 'clsx';

import './Avatar.scss';

const AvatarComponent = (props) => {
	return (
		<div
			className={clsx(
				{
					avatar: true
				}
                )}
			style={{
				backgroundColor: props.backgroundColor,
				width: props.size,
				height: props.size
			}}
		>
			{props.children}
		</div>
	);
};

export default AvatarComponent;
