import '../src/utils/polyfill/';
import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureAppStore from 'AppStore/configureStore';
import { render as reactDomRender } from 'react-dom';
import * as serviceWorker from './serviceWorker';

import App from './App';

const appStore = configureAppStore();

const render = Component => reactDomRender(
  <Provider store={appStore.store}>
      <ConnectedRouter history={appStore.history}>
          <Component/>
      </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


