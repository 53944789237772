import React from 'react';
import {Link} from 'react-router-dom';
import getText from 'AppUtils/language';

import FormField from '../../../../../../common/form-field/FormFieldComponent';
import Button from '../../../../../../common/button/ButtonComponent';

import './Password.scss';

const PasswordComponent = (props) => {
	return (
		<div className="unsubscribe-password">
			<p className="description"></p>
			<form onSubmit={(e) => props.handleSubmit(e)}>
				<div className="inputs-wrapper">
					<div className="shadow-wrapper">
						<FormField
							{...props.formData.password}
							handleChange={(element) => props.handleInputChange(element)}
							validationMessage={props.formData.password.validationMessage}
							maxLength={props.pin.maxLength ? props.pin.maxLength : false}
							rtl={props.rtl}
						/>
					</div>
				</div>

				<button type="submit">
					<Button
						fullWidth={true}
						boxShadow={true}
					>
						{getText('unsubscribe[button_unsubscribe')}
					</Button>
				</button>
			</form>
			<div className="btn-actions">
				<Link to="/">
					<Button
						outline={true}
					>
						{getText('unsubscribe[button_cancel')}
					</Button>
				</Link>
			</div>
		</div>
	);
};

export default PasswordComponent;
