import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import clsx from 'clsx';
import getText from 'AppUtils/language';
import moment from 'moment';

import './BannersSlider.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Moment from 'react-moment';

const BannersSlider = (props) => {

	const settings = {
		dots: true,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: '18px',
		autoplay: true,
		autoplaySpeed: 3500,
	};

	const renderGames = () => {
		if(props.banners !== undefined && props.banners.items !== undefined){
			return props.banners.items.map((banner, i) => (
				<div
					className="banner"
					key={banner.id}
				>
					<div className="a"
						onClick={(e) => props.onBannerClick(e, banner.url)}
						style={{
							backgroundImage: `url(${banner.image})`
						}}
					>
						<div className="title-game">
							<h5>{banner.title[props.lang]}</h5>
						</div>
					</div>
					<p></p>
				</div>
			))
		} else {
			return null;
		}

	};

	return (
		<div className="games-slider">
			<Slider {...settings}>
				{renderGames()}
			</Slider>
		</div>
	);
};

export default BannersSlider;
