import React from 'react';

import './AvatarProgress.scss';

const AvatarProgressComponent = (props) => {
	return (
		<div className="avatar-progress">
			<svg id="level-progress" xmlns="http://www.w3.org/2000/svg"
				 width="120" height="120" viewBox="0 0 120 120">
				<circle cx="60" cy="60" r="54" fill="none" stroke="#fff" strokeWidth="10"></circle>
				<circle id="level-arc" cx="60" cy="60" r="54" fill="none" stroke="#ffd300" strokeWidth="10" style={{strokeDasharray: 339.292, strokeDashoffset: 84.823}}></circle>
			</svg>
			<img className="level-avatar" src="//promo.test2020.msbeecoded.ro/levels/3-pro.png" alt="" />
		</div>
	);
};

export default AvatarProgressComponent;
