import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import clsx from 'clsx';
import getText from 'AppUtils/language';
import moment from 'moment';

import './GamesSlider.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Moment from 'react-moment';

const GamesSlider = (props) => {

	const settings = {
		dots: true,
		arrows: false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: '18px',
	};

	const renderGames = () => {
		return props.games.map((game, i) => (
			<div
				className="item"
				key={game.id}
			>
				<Link
					to={props.virtualGames ? game.data.launchUrl : `/play/${game.id}`}
					style={{
						backgroundImage: `url(${game.image})`
					}}
					onClick={(e) => props.handlePlayGame(e, game.id, game.data.launchUrl, game.type)}
				>
					<div className="title-game">
						<h5>{game.name[props.lang]}</h5>
						{game.data !== undefined && game.data.next !== undefined ?
							<h6>{getText('games[live_game_at]')} <Moment local={props.lang} format={"DD/MM/YYYY HH:mm:ss"}>{Object.keys(game.data.next)[0]}</Moment></h6>
						:
						null
						}
						{
							game.data.next === undefined ?
								<h6>{game.subtitle[props.lang]}</h6>
							:
								null
						}
					</div>
					<p className={clsx({locked: props.userInfoGames[game.id] === 'locked' && !props.virtualGames, completed: props.userInfoGames[game.id] === 'completed' && !props.virtualGames})}>
						{props.userInfoGames[game.id] === 'playable' || props.virtualGames ?
								getText('games[text_play]')
							:
							props.userInfoGames[game.id] === 'completed' ?
								getText('games[text_completed]')
							:
							props.userInfoGames[game.id] === 'locked' ?
								getText('games[text_locked]')
							:
								getText('games[text_play]')
						}
					</p>
				</Link>
			</div>
		))
	};

	return (
		<div className="games-slider">
			<Slider {...settings}>
				{renderGames()}
			</Slider>
		</div>
	);
};

export default GamesSlider;
