import React from 'react';

import './FormField.scss';

const FormFieldComponent = (props) => {

	const renderTemplate = () => {
		let formTemplate = null;

		switch(props.element) {
			case('input'):
				formTemplate = (
					<input
						id={props.config.id}
						name={props.config.name}
						type={props.config.type}
						placeholder={props.config.placeholder}
						required={props.validation.required}
						value={props.value}
						onChange={(event) => props.handleChange({event, blur: false})}
						onBlur={(event) => props.handleChange({event, blur: true})}
						className={props.className}
						disabled={props.disabled ? props.disabled : false}
						maxLength={props.maxLength}
					/>
				);
				break;
			default:
				formTemplate = null;
		}

		return formTemplate;
	}

	return (
		<div className={`form-field ${props.rtl ? 'rtl' : ''}`}>
			{props.prefix ? <p className="prefix">+{props.prefix}</p> : null}
			{renderTemplate()}
			{props.validationMessage &&
				<p className="validation-message">{props.validationMessage}</p>
			}
		</div>
	);
};

export default FormFieldComponent;
