import React from 'react';

import Ribbon from '../../../common/ribbon/RibbonComponent';

import './Footer.scss';

import Logo from '../../../resources/img/logo-256.png';

const FooterComponent = (props) => {
	return (
		<footer>
			{/*<div className="powered-by">*/}
			{/*	<p>powered by</p>*/}
			{/*	<img src={Logo} alt=""/>*/}
			{/*</div>*/}
			{/*{props.authIsAuthenticated &&*/}
			{/*	<div className="prize">*/}
			{/*		<img src="" alt=""/>*/}
			{/*		<div className="prize-description">*/}
			{/*			<span>You're in for</span>*/}
			{/*			<strong>Galaxy note 8</strong>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*}*/}
			{/*{props.authIsAuthenticated &&*/}
			{/*	<div className="play-for">*/}
			{/*		<Ribbon*/}
			{/*			className="banner-small"*/}
			{/*			fill="rgb(230, 50, 49)"*/}
			{/*		/>*/}
			{/*		<p className="banner-content">*/}
			{/*			<span className="banner-title">PLAY FOR</span>*/}
			{/*			<strong className="banner-points">400</strong>*/}
			{/*			<span className="banner-points-text">POINTS</span>*/}
			{/*		</p>*/}
			{/*	</div>*/}
			{/*}*/}
		</footer>
	);
};

export default FooterComponent;
