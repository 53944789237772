import React from 'react';

import Ribbon from '../../../common/ribbon/RibbonComponent';

import './Confirm.scss';
import getText from '../../../utils/language';
import Button from '../../../common/button/ButtonComponent';

const ConfirmComponent = (props) => {
	return (
		<div className="confirm-component vertical-center">
			<div className="confirm-info">
				<Ribbon
					width={280}
					sizeBig={true}
				/>
				<div className="info-content">
					<span className="confirm-title">You're now playing for</span>
					<strong className="confirm-points">200</strong>
					<span className="confirm-points-text">POINTS</span>
				</div>
			</div>
			<Button
				fullWidth={true}
				boxShadow={true}
			>
				I like it! Play now!
			</Button>
		</div>
	);
};

export default ConfirmComponent;
